/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import Layout from "../layout"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Container from "../components/container"
import Header from "../layout/header"
import Hero from "../layout/Hero"
import Seo from "../components/seo"

export default function Sitemap() {
  const data = useStaticQuery(graphql`
    query PropertyList {
      blockFragment: sanityPageDefinition(slug: { current: { eq: "home" } }) {
        _rawEntities(resolveReferences: { maxDepth: 10 })
        _rawHero(resolveReferences: { maxDepth: 10 })
      }
      county: allSanityCounty {
        nodes {
          countyName
        }
      }
      additionalcounty: allProperty {
        group(field: CountyOrParish) {
          fieldValue
        }
      }
      type: allSanityPropertyType {
        nodes {
          propertyTypeName
        }
      }
      region: allSanityRegion {
        nodes {
          regionName
        }
      }
    }
  `)
  function regionLink(text) {
    return (
      text
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "") + "-region-ranches-for-sale"
    )
  }
  function countyLink(text) {
    return (
      text
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "") + "-county-ranches-for-sale"
    )
  }
  function typeLink(text) {
    return (
      text
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "") + "-ranches-for-sale"
    )
  }
  let newCountyArray = []
  console.log(data)
  data.county.nodes.forEach(node => {
    newCountyArray.push(node)
  })
  data.additionalcounty.group.forEach(node => {
    let countyInArray = false
    newCountyArray.forEach(nodeInner => {
      if (node.fieldValue == nodeInner.countyName) {
        countyInArray = true
      }
    })
    if (!countyInArray) {
      let countyObject = { countyName: node.fieldValue, id: "additional" }
      newCountyArray.push(countyObject)
    }
  })
  return (
    <Layout>
      <Seo title="Property Listing Sitemap" />
      <div
        id="sitemap-hero-container"
        sx={{
          ".hero-copy-container": {
            display: "none",
          },
        }}
      >
        <Hero node={data.blockFragment}></Hero>

        <div
          sx={{
            position: "absolute",
            bottom: "90px",
            width: "100%",
            h1: {
              color: "white",
              marginLeft: ["0px", "60px", "60px"],
            },
          }}
        ></div>
      </div>
      <Container>
        <div
          sx={{
            width: "100%",
            margin: ["0px auto"],
            maxWidth: "860px",
            padding: "80px 0px",
          }}
        >
          <h1 sx={{ color: "grayBlk" }}>Property Listing Sitemap</h1>
          <div sx={{ color: "grayBlk" }}>
            <h2>Properties by County</h2>
            <ul>
              {newCountyArray
                .sort((a, b) => {
                  const nameA = a.countyName.toUpperCase() // ignore upper and lowercase
                  const nameB = b.countyName.toUpperCase() // ignore upper and lowercase
                  if (nameA < nameB) {
                    return -1
                  }
                  return 1
                })
                .map((node, index) => (
                  <li index={index}>
                    <Link
                      to={"/" + countyLink(node.countyName)}
                      sx={{ color: "#484242 !important" }}
                      title={`${node.countyName} index page`}
                    >
                      {node.countyName}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
